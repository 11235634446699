@import '../../../assets/scss/dimensions';
@import '../../../assets/scss/numbers';
@import '../../../assets/scss/colors';

.actions-strip-container {
  height: 159px;
  width: $screen-width;
  bottom: 0;
  overflow: hidden;
  background-color: $actions-strip-container-color;

  .actions-strip {
    background-color: $actions-strip-background-color;
    overflow-x: hidden;
  }

  .actions-strip-left-highlight {
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, $actions-strip-caret-highlight-color-stop1 49%, $actions-strip-caret-highlight-color-stop2 100%);
    background-size: $actions-strip-left-caret-width 100%;
    background-position: left;
  }

  .actions-strip-right-highlight {
    background-repeat: no-repeat;
    background-image: linear-gradient(to left, $actions-strip-caret-highlight-color-stop1 51%, $actions-strip-caret-highlight-color-stop2 100%);
    background-size: $actions-strip-right-caret-width 100%;
    background-position: right;
  }

  .action-items-container {
    display: inline-flex;
    height: 80px;
    width: $screen-width;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 132px;
    transition: margin $action-items-transition-duration;
  }

  .action-strip-highlight {
    margin-left: 132px;
    position: absolute;
    align-items: center;
    min-width: 537px;
    height: 80px;
    background-color: $actions-strip-highlight-color;
    padding-left: 16px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .action-strip-highlight-border {
    border: solid 3px $primary-text-color;
  }

  .actions-strip-message-container {
    pointer-events: auto;
    height: 79px;
    width: $screen-width;

    .actions-strip-message {
      width: fit-content;
      height: 40px;
      margin: 13px 0 0 148px;
      font-family: SourceSansProLight, sans-serif;
      font-size: 33px;
      font-weight: $primary-font-weight;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $primary-text-color;
    }
  }
}

@media only screen and (max-height: 720px) {
  .actions-strip-container {
    height: 106px;
    width: $screen-width-720p;
    overflow: hidden;
    bottom: 0;

    .actions-strip-items {
      height: 53px;
      width: $screen-width-720p;
    }

    .action-strip-highlight {
      margin-left: 88px;
      min-width: 358px;
      height: 53px;
      border: solid 2px $primary-text-color;
      padding-left: 11px;
    }

    .actions-strip-message-container {
      height: 53px;
      width: $screen-width-720p;

      .actions-strip-message {
        height: 32px;
        margin: 7px 0 0 99px;
        font-size: 22px;
        font-weight: $primary-font-weight;
      }
    }
  }
}
